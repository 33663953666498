<template>
	<v-container class="forecast">
		<PlaceHeader :item="item"></PlaceHeader>
		<PlaceLocalMenu :item="item"></PlaceLocalMenu>
		<div class="main-body" style="height: 800px;">
			<vue-loading v-if="loading" type="spin" color="#333" :size="{ width: '300px', height: '300px' }"></vue-loading>
			<ChartForecastGraph v-if="!loading" :item="item"></ChartForecastGraph>
		</div>
	</v-container>
</template>

<script>
import PlaceHeader from "@/components/PlaceHeader.vue";
import PlaceLocalMenu from "@/components/PlaceLocalMenu.vue";
import ChartForecastGraph from "@/components/ChartForecastGraph.vue";
import { VueLoading } from 'vue-loading-template'

export default {
	computed: {
	},
	components: {
		PlaceHeader
		,PlaceLocalMenu
		,ChartForecastGraph
		,VueLoading
	},
	mounted() {
	},
	created() {
		this.item = this.$store.getters.getPlaceByIndex(this.$route.params.id);
	},
	methods: {
	},
	data() {
		return {
			item: null,
			loading: false
		};
	},
};
</script>

<style>
</style>
