<template>
  <div>
    <div class="block input without-footer">
      <div class="header" @click="displayForm = !displayForm" style="cursor:pointer;">予測日数の入力</div>
      <div v-if="displayForm" class="body">
        <div>
          <b-form-input id="date_interval" v-model="date_interval" size="1" placeholder=""></b-form-input>日後 (最長11日) の
          <b-button id="calcualte" @click="onClickCalculate($refs.linechart)">水位を予測</b-button>
        </div>
      </div>
      <div v-if="!displayForm" class="footer"></div>
    </div>
    <div class="block">
      <div class="header" @click="displayChart = !displayChart" style="cursor:pointer;">水位予測</div>
      <div v-if="displayChart" class="body" @mousemove="onMouseMove($event, $refs.linechart)">
        <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
        ref="linechart"
      />
        <!-- <div class="overlay"></div> -->
      </div>
      <div v-if="displayChart" class="footer">
        <div>
          <!--
          <button @click="onClickRange($refs.linechart, 12 )">12時間</button>
          <button @click="onClickRange($refs.linechart, 24 )">1日</button>
          <button @click="onClickRange($refs.linechart, 24*3 )">3日</button>
          <button @click="onClickRange($refs.linechart, 24*7 )">7日</button>
          <button @click="onClickRange($refs.linechart, 24*30 )">30日</button>
          -->
        </div>
        <div>
          <button @click="onClickZoomOut($refs.linechart)">-</button>
          <button @click="onClickZoomIn($refs.linechart)">+</button>
          <button @click="onClickResetZoom($refs.linechart)">リセット</button>
        </div>
      </div>
      <div v-if="!displayChart" class="footer"></div>
    </div>
  </div>
  
</template>
  
<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'
import annotationPlugin from 'chartjs-plugin-annotation'; // アノテーション描画で必要
import zoomPlugin from 'chartjs-plugin-zoom';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler,
  TimeScale,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  PointElement,
  Filler
  ,annotationPlugin  // アノテーション描画で必要
  , zoomPlugin
)

// Bootstrap-Vue references : 
// https://bootstrap-vue.org/docs/components/form-input
// https://bootstrap-vue.org/docs/components/form-datepicker

export default {
  name: 'ForecastChart',
  components: {
    LineChartGenerator
  }
  ,
  async created() {
    this.startlevel = this.item.waterlevel.level[this.item.waterlevel.level.length-1];
		this.fulllevel = this.item.fulllevel;
    this.apcp = Object.assign({},this.item.apcp);
    this.waterlevel = this.item.waterlevel.level.concat();
    this.chartData.labels = this.item.waterlevel.ts.concat();
		let data = this.setForecastData();
    console.log(this);
    this.setChartData(data);
  }
  ,
  mounted() {
	}
  ,
  props: {
    chartId: {
      type: String,
      default: 'block-water-history'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    // plugins: {
    //   type: Array,
    //   default: () => []
    // }
    // ,
    item: {
      type: Object,
      default: () => []
    }
  },
  computed: {
  },
  data() { 
    return {
      displayChart: true,
      displayForm: false,
      date_interval: 11,
      date: new Date(),
      chart:null,
      chartData: {},
      chartOptions: {},
      legend_width: 150,
      thresholds: function() { return this.$store.getters.getForecastParams },
			startlevel: null,
      fulllevel: null,
      waterlevel: null,
			apcp: {},
			predictWaterLevels: null,
			log: "",
      plugins: [
          {
            // 特殊なレジェンドの作成
            id: 'custom_legends',
            beforeDraw: (function (chart) {
              // console.log(chart)
              try{
                const w = this.legend_width
                let h = 50
                const scales = chart.scales
                const chartArea = chart.chartArea
                // const thresholds = this.item.alertThresholds
                // const c = this.$store.getters.getColors
                let y = 0
                let ctx = chart.ctx;

                const xpos_0 = chartArea.left + chartArea.width 
                const xpos_1 = chart.width - w

                for(let key in this.thresholds()){
                  const target_item = this.thresholds()[key]
                  // console.log(scales,xpos_0,xpos_1)
                  if(target_item.draw_legend){
                    let ypos = null;
                    let the_level = null;
                    h = 50
                    if(target_item.name == "常時満水位"){
                      the_level = 0;
                      ypos = scales.water_level.getPixelForValue( the_level )
                      ctx.fillStyle = target_item.color;
                      ctx.fillRect(chart.width - w, y, w, h);
                      ctx.fillStyle = target_item.text_color;
                      ctx.font = "bold 16px sans-serif";
                      ctx.textAlign = "center";
                      ctx.fillText( target_item.name, chart.width  - w/2, y+18);
                      ctx.font = "bold 24px sans-serif";
                      ctx.fillText( '0.00m' , chart.width  - w/2, y+45);
                    } else if(target_item.name == "予測水位"){
                      the_level = (this.predictWaterLevels[this.date_interval-1]).toFixed(2);
                      ypos = scales.water_level.getPixelForValue( the_level )
                      ctx.fillStyle = target_item.color;
                      ctx.fillRect(chart.width - w, y, w, h);
                      ctx.fillStyle = target_item.text_color;
                      ctx.font = "bold 16px sans-serif";
                      ctx.textAlign = "center";
                      ctx.fillText( target_item.name, chart.width  - w/2, y+18);
                      ctx.font = "bold 24px sans-serif";
                      let prefix = "+";
                      if (the_level < 0) prefix = "";
                      ctx.fillText( prefix + the_level + 'm' , chart.width  - w/2, y+45);
                    } else if (target_item.name == "観測水位") {
                      h = 40
                      ctx.fillStyle = target_item.color;
                      ctx.fillRect(chart.width - w, y, w, h);
                      ctx.fillStyle = target_item.text_color;
                      ctx.font = "bold 13px sans-serif";
                      ctx.textAlign = "center";
                      ctx.fillText( target_item.name, chart.width  - w * 0.5, y + 25);
                    }
                    if(target_item.draw_line){
                      ctx.beginPath () ;
                      ctx.strokeStyle = target_item.color;
                      ctx.lineWidth = 2 ;
                      ctx.moveTo( chartArea.left, ypos ) ;
                      ctx.lineTo( xpos_0, ypos ) ;
                      ctx.stroke() ;

                      ctx.beginPath () ;
                      ctx.strokeStyle = target_item.color;
                      ctx.lineWidth = 2 ;
                      
                      ctx.moveTo( xpos_0, ypos ) ;
                      ctx.lineTo( xpos_1, y + h/2 )
                      
                      ctx.stroke() ;
                    }
                    y += h+5
                  }
                }
                // 単位
                ctx.textAlign = 'left';
                ctx.fillStyle = "#555";
                ctx.font = "normal 14px sans-serif";
                ctx.fillText('水位(m)', 10, 14 );
                ctx.restore();

              } catch( e){
                  console.log(e)
              }
            }).bind(this)
          }
        ]
    }
  }
  ,methods : {
    onClickCalculate(c){
      if (this.date_interval > 0 && this.date_interval < 12) {
        let data = this.setForecastData();
        this.setChartData(data);
      } else {
        alert("目標日時は1日〜11日の間で設定して下さい");
      }
    }
    ,
    onClickRange(e,r){
      console.log(e,r)
    },
    onClickZoomIn(e){
      let chart = e.getCurrentChart()
      if(chart.getZoomLevel() < 10){
        chart.zoom( 1.2 , 'x')
      }
    }
    ,
    onClickZoomOut(e){
      let chart = e.getCurrentChart()
      chart.zoom( 0.8 , 'x')
    }
    ,
    onClickResetZoom(e){
      let chart = e.getCurrentChart()
      chart.resetZoom()
    }
    ,
    onMouseMove(e, c){
      // console.log(e,c)
      let chart = c.getCurrentChart()
      let area = chart.chartArea
      if(area.left -10 < e.offsetX && e.offsetX < area.left + area.width +10 && area.top - 10  < e.offsetY && e.offsetY < area.top+area.height + 10){
        document.body.classList.add('can-zoom')
      } else {
        document.body.classList.remove('can-zoom')
      }
    }
    ,
    scrollTop: function() {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		logStr: function(array) {
			let str = "[ ";
			for (var i = 0; i < array.length; i++) {
				let val = Math.round(array[i] * 100.0) / 100.0;
				if (i == array.length-1) str += val;
				else str += val + ", ";
			}
			return str + " ]";
		},
		reloadGraph: function() {
			this.log = "";
			this.setForecastData();
		},
		calcAll: function() {
      let apcp = this.calcMiddleAPCP();
      this.log += "APCP: " + this.logStr(apcp) + "\n";
			let acm_apcp = this.calcAcmAPCP(apcp);
			let acm_storage = [];
			let daily_storage = [];
      let current_level = this.item.waterlevel.level[this.item.waterlevel.level.length-1];
			this.log += "積算雨量: " + this.logStr(acm_apcp) + "\n";
			for (let i = 0; i < this.date_interval; i++) {
				acm_storage[i] = acm_apcp[i] * this.item.regressionCoefficient;
				// acm_storage[i] = acm_apcp[i] * this.item.regressionBottomCoefficient + this.item.regressionBottomOffset;
				if (acm_storage[i] <= 0) acm_storage[i] = 0;
				if (i == 0) daily_storage[i] = acm_storage[i];
				else if (acm_storage[i] == 0) daily_storage[i] = 0;
				else daily_storage[i] = acm_storage[i] - daily_storage[i - 1];
        // console.log(i + ": " + acm_apcp[i] + " / " + daily_storage[i]);
			}
			this.log += "日回復貯水量: " + this.logStr(daily_storage) + "\n";

      // ここから予測水位の計算
		
			// 予測貯水量を計算する
			this.predictWaterLevels = [];
			let waterStorage = (this.item.pondBottomArea * 2.0 + this.startlevel * this.item.c) * this.startlevel * 0.5;
			for (let i = 0; i < daily_storage.length; i++) {
        waterStorage += daily_storage[i];
				this.predictWaterLevels[i] =
						this.item.vh[0] * waterStorage +
						this.item.vh[1] *
							Math.pow(waterStorage, 2.0) +
						this.item.vh[2] *
							Math.pow(waterStorage, 3.0) +
						this.item.vh[3] *
							Math.pow(waterStorage, 4.0) +
						this.item.vh[4] *
							Math.pow(waterStorage, 5.0) +
						this.item.vh[5];
			}
			this.log += "予測貯水位 (中央値): " + this.logStr(this.predictWaterLevels) + "\n";
		},
    calcMiddleAPCP: function() {
      let data = [];
			for (const key of Object.keys(this.apcp)) {
        let ensemble = this.apcp[key];
        let ensVal = ensemble[2] + ensemble[3] + ensemble[4];
        data.push({"id": key, "value": ensVal});
      }
      data.sort((a, b) => a.value - b.value);
      return this.apcp[data[5].id];
    },
		calcAcmAPCP: function(apcp) {
			let acm_apcp = [];
			let total = 0;
			for (var i = 0; i < this.date_interval; i++) {
        if (!apcp[i]) apcp.push(0);
				if (apcp[i] != 0) {
					acm_apcp[i] = apcp[i] + total;
					total += apcp[i];
				} else {
					acm_apcp[i] = 0;
					total = 0;
				}
			}
			// console.log(acm_apcp);
			// this.log += "積算雨量: " + this.logStr(acm_apcp) + "\n";
			return acm_apcp;
		},
		setForecastData: function() {
      let data = {
        labels:[] // 日時
        ,water_level: [] // 観測水位
        ,predicted_level:[] //予測水位
      }
      // console.log(this.waterlevel);
      if (!this.waterlevel) return;
      for (let i = 0; i < this.waterlevel.length; i++) {
        if (typeof this.chartData.labels[i] == "string") {
          this.chartData.labels[i] = new Date(this.chartData.labels[i]);
        }
        data.labels.push(this.chartData.labels[i]);
        data.water_level.push(this.waterlevel[i]);
        if (i != this.waterlevel.length-1) {
          data.predicted_level.push(null);
        }
			}
      // ここから予測計算
			this.calcAll();
      let prev = new Date(data.labels[data.labels.length-1].getTime());
      let interval = 60 * 24;
      data.predicted_level.push(this.startlevel);
			for (let i = 0; i < this.predictWaterLevels.length; i++) {
				prev = new Date(prev.setMinutes(prev.getMinutes() + interval));
        data.labels.push(new Date(prev.getTime()));
        data.water_level.push(null);
        data.predicted_level.push(this.predictWaterLevels[i]);
			}
			// 11日後以降のデータ
			for (var i = 0; i < 3; i++) {
				prev = new Date(prev.setMinutes(prev.getMinutes() + interval));
        data.labels.push(new Date(prev.getTime()));
        data.water_level.push(null);
        data.predicted_level.push(null);
			}
      console.log(this.log);
      return data;
		},
    setChartData: function(data) {
        this.chartData = {
          labels: null,
          datasets: [
            {
              label: '水位'
              ,fill : "start"
              ,backgroundColor: 'rgba(113,192,255,0.8)'
              ,borderColor: "#3e95cd"
              ,borderWidth: 0
              ,data: null
              ,yAxisID: 'water_level'
            },
            {
              label: '予測水位'
              ,fill : "start"
              ,borderColor: 'rgba(107,207,215,1)'
              ,backgroundColor: 'rgba(147,247,255,0.6)'
              ,borderWidth: 1
              ,data: null
              ,yAxisID: 'water_level'
            }
          ]
        };
        this.chartOptions = {
          responsive: true,
          maintainAspectRatio: false
          ,
          layout: {
              padding: {
                right: 150 + 50
                ,top: 30
              }
          }
          ,
          scales: {
            x: {
              ticks: {
                callback: function(val) { // , index
                // let num_ticks = this.ticks.length
                let s = ''
                // if(index % (num_ticks-1) === 0 || index % Math.floor(num_ticks / 20) === 0 ){
                let ds = new Date( this.getLabelForValue(val) )
                s = (ds.getMonth() + 1) + '/' + ds.getDate() + ' ' + ('0' + ds.getHours()).slice(-2) + ':' + ('0' + ds.getMinutes()).slice(-2); 
                // }
                return s;
              },
                color: 'black',
              }
            },
            water_level: {
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                stepSize: 0.2
              },
              min: (function(){ return Math.min(...this.item.waterlevel.level.concat([parseFloat(this.fulllevel)]).concat(this.predictWaterLevels)) - 0.5}).bind(this),
              max: (function(){ return Math.max(...this.item.waterlevel.level.concat([parseFloat(this.fulllevel)]).concat(this.predictWaterLevels)) + 0.5}).bind(this)
            },
          },
          plugins: {
              tooltip: {
                  callbacks: {
                    title: function(context){
                      let title = new Date(context[0].label);
                      return title.getFullYear() + '/' + (title.getMonth() + 1) + '/' + title.getDate() + ' ' + ('0' + title.getHours()).slice(-2) + ':' + ('0' + title.getMinutes()).slice(-2)
                    },
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ' : ';
                        }
                        if (context.parsed.y !== null) {
                          let prefix = "";
                          if (context.parsed.y >= 0) prefix = "+";
                          label += prefix + new Intl.NumberFormat('en-US', { style: 'decimal', maxDigits: 2 }).format(context.parsed.y) + ' m';
                        }
                        return label;
                    }
                  }
              },
              legend: {
                display: false,
                position: 'right'
              },
              zoom:{
                limits:{
                  // x : { min: 20, max: 100 }
                },
                zoom : {
                  wheel : {
                    enabled: true,
                    speed : 0.01
                  }
                  ,mode : 'x'
                }
                ,pan : {
                    enabled: true
                    ,mode : 'x'
                  }
              }
              , autocolors: false
              , annotation: { // チャート内に線やボックスを描ける
                  animations: {
                    numbers: {
                      properties: [], // keep empty to avoid a bug // 'x', 'y', 'x2', 'y2', 'width', 'height', 'radius'
                      type: 'number'
                    },
                  },
                  annotations: {
                    box1: { // black line
                      type: 'box',
                      xMin:  (function(){ return this.waterlevel.length+this.predictWaterLevels.length-1 }).bind(this),
                      xMax: (function(){ return this.waterlevel.length+this.predictWaterLevels.length-1 }).bind(this),
                      yMin: (function(){ return this.predictWaterLevels[0] - 1 }).bind(this),
                      yMax: (function(){ return Math.max(...this.item.waterlevel.level.concat([parseFloat(this.fulllevel)]).concat(this.predictWaterLevels)) + 0.1}).bind(this),
                      borderWidth : 3,
                      backgroundColor: 'rgba(0,0 ,0,1)',
                      borderColor: 'rgba(0,0 ,0,1)'
                    }
                    ,label1: {
                      type: 'label',
                      xValue:  (function(){ return this.waterlevel.length+this.predictWaterLevels.length-1 }).bind(this),
                      yValue:  (function(){ return Math.max(...this.item.waterlevel.level.concat([parseFloat(this.fulllevel)]).concat(this.predictWaterLevels)) + 0.25}).bind(this) ,
                      textAlign : 'center', 
                      color: 'rgba(0,0,0,1)',
                      backgroundColor: 'rgba(255,255,255,1)',
                      content: (function(){ 
                        const target_date = this.chartData.labels[ this.waterlevel.length + this.predictWaterLevels.length - 1 ] 
                        return [
                          target_date.getFullYear()+'/'+(target_date.getMonth()+1)+'/'+(target_date.getDate()) 
                        ]
                      }).bind(this),
                      font: {
                        size: 18
                        ,weight:'bold'
                      }
                    }
                  }
              }
            
          }
      }
      this.chartData.labels = data.labels;
      this.chartData.datasets[0].data = data.water_level;
      this.chartData.datasets[1].data = data.predicted_level;
    }
  }
}
</script>
<style lang="scss">

.input {
  .body > div { 
    display: flex; align-items: center; justify-content: center; font-weight: bold; font-size: 18px; margin: 1em auto;  
    span.green { background-color: transparent !important; color: #17A22B ;  }
    #datepicker__outer_ { width: 30%; margin: 0 .5em;  }
    input {
      margin: 0 .5em; 
      &#date_interval { width: 60px; }
    }
    button#calcualte { background-color:#3932B2; margin: 0 .5em;  }
  }
}



</style>